// src/components/UsersTable/UsersTable.jsx

import React, { useState, useEffect, useContext } from 'react';
import {
    Button,
    Table,
} from 'reactstrap';
import '../../assets/css/CustomTable.css';
import config from 'config/config';
import axios from 'axios';
import { PageContentContext } from 'context/PageContentContext';
import { FaEye, FaEdit, FaTrash, FaToggleOn, FaToggleOff } from 'react-icons/fa'; // Import icons

const UsuariosTable = ({ title }) => {
    const [sortConfig, setSortConfig] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const token = localStorage.getItem('naseda_token');

    const { setPageContent } = useContext(PageContentContext);

    // Debounce search term
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300); // 300ms debounce

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        const fetchData = async () => {
            const params = new URLSearchParams();
            params.append('page', currentPage);
            params.append('limit', itemsPerPage);

            // Include search term
            if (debouncedSearchTerm) {
                params.append('searchTerm', debouncedSearchTerm);
            }

            // Include sorting parameters
            if (sortConfig) {
                params.append('sortKey', sortConfig.key);
                params.append('sortDirection', sortConfig.direction);
            }

            try {
                const response = await axios.get(
                    `${config.backendURL}/users?${params.toString()}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                const result = response.data;
                setData(result.users); // Data for the current page
                setTotalPages(result.totalPages); // Total number of pages
            } catch (error) {
                console.error('Error fetching data', error);
                // Handle error appropriately
            }
        };

        fetchData();
    }, [
        currentPage,
        itemsPerPage,
        debouncedSearchTerm,
        sortConfig,
        token,
    ]);

    useEffect(() => {
        if (data) {
            // Create an object for the page content
            const pageContentObject = {
                purpose:
                    'Esta es la página donde puedes gestionar los usuarios del sistema. Puedes buscar usuarios y realizar acciones como ver detalles, editar o eliminar usuarios.',
                usersData: data, // The actual data
            };

            // Convert the pageContentObject to a JSON string
            const content = JSON.stringify(pageContentObject, null, 2);

            console.log(content);
            setPageContent(content);
        }
    }, [data, setPageContent]);

    // Function to handle sorting
    const onSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
        setCurrentPage(1); // Reset to first page on sort
    };

    // Function to get the sort indicator
    const getSortIndicator = (key) => {
        if (!sortConfig) return null;
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? ' 🔼' : ' 🔽';
        }
        return null;
    };

    // Pagination Component
    const PaginationComponent = () => {
        if (totalPages === 0) return null;

        const pages = [];

        // Always show the first page
        pages.push(1);

        // Calculate the page numbers to show between ellipses
        let leftSiblingIndex = Math.max(currentPage - 1, 2);
        let rightSiblingIndex = Math.min(currentPage + 1, totalPages - 1);

        const showLeftEllipsis = leftSiblingIndex > 2;
        const showRightEllipsis = rightSiblingIndex < totalPages - 1;

        // Add left ellipsis if needed
        if (showLeftEllipsis) {
            pages.push('...');
        } else {
            // Add pages between first page and current page
            for (let i = 2; i < leftSiblingIndex; i++) {
                pages.push(i);
            }
        }

        // Add the page numbers around the current page
        for (let i = leftSiblingIndex; i <= rightSiblingIndex; i++) {
            pages.push(i);
        }

        // Add right ellipsis if needed
        if (showRightEllipsis) {
            pages.push('...');
        } else {
            // Add pages between current page and last page
            for (let i = rightSiblingIndex + 1; i < totalPages; i++) {
                pages.push(i);
            }
        }

        // Always show the last page if totalPages > 1
        if (totalPages > 1) {
            pages.push(totalPages);
        }

        return (
            <div className="pagination">
                <button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                >
                    Anterior
                </button>
                {pages.map((page, index) => {
                    if (page === '...') {
                        return (
                            <span key={`ellipsis-${index}`} className="ellipsis">
                                ...
                            </span>
                        );
                    } else {
                        return (
                            <button
                                key={page}
                                onClick={() => setCurrentPage(page)}
                                className={currentPage === page ? 'active' : ''}
                            >
                                {page}
                            </button>
                        );
                    }
                })}
                <button
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                >
                    Siguiente
                </button>
            </div>
        );
    };

    // Function to handle user actions
    const handleUserAction = (userId, action, currentStatus) => {
        switch (action) {
            case 'view':
                alert(`Ver detalles del usuario con ID: ${userId}`);
                // Implement navigation or modal to show user details
                break;
            case 'edit':
                alert(`Editar usuario con ID: ${userId}`);
                // Implement navigation or modal to edit user
                break;
            case 'delete':
                alert(`Eliminar usuario con ID: ${userId}`);
                // Implement delete functionality
                break;
            case 'toggleActive':
                handleToggleActive(userId, currentStatus);
                break;
            default:
                break;
        }
    };

    const handleToggleActive = async (userId, currentStatus) => {
        try {
            const response = await axios.patch(
                `${config.backendURL}/users/${userId}/active`,
                { active: !currentStatus },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Update the user in the data array
            const updatedUser = response.data.user;
            setData((prevData) =>
                prevData.map((user) => (user._id === userId ? updatedUser : user))
            );
        } catch (error) {
            console.error('Error updating user status:', error);
            // Handle error appropriately, e.g., show a notification
        }
    };


    return (
        <>
            {/* Search Controls */}
            <div className="search-filter-container">
                <div className="custom-title">
                    <h1>{title}</h1>
                </div>
                <div className="search-container">
                    <input
                        type="text"
                        placeholder="Busca usuario..."
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setCurrentPage(1); // Reset to first page on search
                        }}
                        className="search-input"
                    />
                </div>
                {/* Remove the filter button */}
            </div>

            {/* Table */}
            <Table className="custom-table">
                <thead>
                    <tr>
                        <th onClick={() => onSort('name')}>
                            Nombre{getSortIndicator('name')}
                        </th>
                        <th onClick={() => onSort('last_name')}>
                            Apellido{getSortIndicator('last_name')}
                        </th>
                        <th onClick={() => onSort('email')}>
                            Correo Electrónico{getSortIndicator('email')}
                        </th>
                        <th onClick={() => onSort('phone_number')}>
                            Teléfono{getSortIndicator('phone_number')}
                        </th>
                        <th onClick={() => onSort('access_level')}>
                            Nivel de Acceso{getSortIndicator('access_level')}
                        </th>
                        <th onClick={() => onSort('active')}>
                            Estado{getSortIndicator('active')}
                        </th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 ? (
                        data.map((user) => (
                            <tr key={user._id}>
                                <td>{user.name}</td>
                                <td>{user.last_name}</td>
                                <td>{user.email}</td>
                                <td>{user.phone_number}</td>
                                <td>{user.access_level.join(', ')}</td>
                                <td className='text-center'>
                                    <Button
                                        size="sm"
                                        style={{color : user.active ? '#6EC531': '#ec0928'}}
                                        onClick={() =>
                                            handleUserAction(user._id, 'toggleActive', user.active)
                                        }
                                    >
                                        {user.active ? <FaToggleOn /> : <FaToggleOff />}
                                    </Button>
                                </td>
                                <td className="">
                                    <div className="d-flex justify-content-around">
                                        <Button
                                            color="info"
                                            size="sm"
                                            onClick={() => handleUserAction(user._id, 'view')}
                                        >
                                            <FaEye />
                                        </Button>
                                        <Button
                                            color="warning"
                                            size="sm"
                                            onClick={() => handleUserAction(user._id, 'edit')}
                                        >
                                            <FaEdit />
                                        </Button>
                                        <Button
                                            color="danger"
                                            size="sm"
                                            onClick={() => handleUserAction(user._id, 'delete')}
                                        >
                                            <FaTrash />
                                        </Button>

                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7" style={{ textAlign: 'center' }}>
                                Sin información
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>

            {/* Pagination */}
            <PaginationComponent />
        </>
    );
};

export default UsuariosTable;