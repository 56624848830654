import React, { } from 'react';
import {
  Container
} from 'reactstrap';

// Importa el componente Header y la imagen de fondo
import Header from 'components/Headers/Header.js';
import background from 'assets/img/brand/fondoprincipal.png';
import CustomTable from './CustomTable';

// src/data.js




const VentaCruzada = () => {
  return (
    <>
      {/* Main Container */}
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <Container fluid className="flex-grow-1 d-flex flex-column">
          <CustomTable title={'Mis Clientes'} />
        </Container>
      </div>
    </>
  );
};

export default VentaCruzada;