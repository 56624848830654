/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";

import {useState} from 'react'
import config from "config/config";
import axios from "axios";
import loadingGif from "../../assets/img/loading/loading.gif"



const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [password, setPassword] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handlePhoneChange = (event) => {
    let value = event.target.value;

    // Remove non-digit characters
    value = value.replace(/\D/g, '');

    // Check prefix and format accordingly
    const prefix = value.slice(0, 2);
    if (['55', '56', '33', '81'].includes(prefix)) {
      if (value.length > 2 && value.length <= 6) {
        value = value.slice(0, 2) + ' ' + value.slice(2);
      } else if (value.length > 6) {
        value =
          value.slice(0, 2) +
          ' ' +
          value.slice(2, 6) +
          ' ' +
          value.slice(6, 10);
      }
    } else {
      if (value.length > 3 && value.length <= 6) {
        value = value.slice(0, 3) + ' ' + value.slice(3);
      } else if (value.length > 6) {
        value =
          value.slice(0, 3) +
          ' ' +
          value.slice(3, 6) +
          ' ' +
          value.slice(6, 10);
      }
    }

    // Update state
    setIsValidNumber(value.replace(/\s/g, '').length === 10);
    setPhoneNumber(value);
    if (event.target.value.trim()) {
      setPhoneError(false);
    }
  };

  // Handle password input change
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    if (event.target.value.trim()) {
      setPasswordError(false);
    }
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;
    setIsLoading(true);
    setLoginError('');
    if (!isValidNumber) {
      setPhoneError(true);
      isValid = false;
    }

    if (!password.trim()) {
      setPasswordError(true);
      isValid = false;
    }

    if (!isValid) {
      setIsLoading(false);
      return;
    }
    await axios
      .post(`${config.backendURL}/login`, {
        phoneNumber: phoneNumber.replace(/\s/g, ''),
        password: password,
      })
      .then((response) => {
        // Store session data
        localStorage.setItem('naseda_token', response.data.token);
        localStorage.setItem('naseda_phone_number', response.data.phone_number);
        localStorage.setItem('naseda_name', response.data.name);
        localStorage.setItem('naseda_last_name', response.data.last_name);
        localStorage.setItem('naseda_email', response.data.email);

        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${localStorage.getItem('naseda_token')}`;
        setTimeout(() => {
          setIsLoading(false);

        }, 1000);

        window.location.reload();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setLoginError(
              'Credenciales incorrectas, por favor verifica tu número de teléfono y contraseña.'
            );
          } else if (error.response.status === 500) {
            setLoginError(
              'Error interno del servidor, por favor inténtalo nuevamente más tarde.'
            );
          }
        } else {
          setLoginError(
            'Se produjo un error, por favor verifica tu conexión a internet o inténtalo nuevamente más tarde.'
          );
        }
        setIsLoading(false);
      });
  };


  return (
    <>
      <Col lg="5" md="7" >

        <Card className="bg-secondary shadow border-0">

          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">

              <h1 className="mt-5">Inicia sesión</h1>
            </div>
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
              <InputGroup
                        className={`input-group-alternative ${
                          phoneError ? 'has-danger' : ''
                        }`}
                      >
                 <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-mobile-button" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Número de teléfono"
                          type="tel"
                          autoComplete="off"
                          value={phoneNumber}
                          onChange={handlePhoneChange}
                          invalid={phoneError}
                        />
                      </InputGroup>
                      {phoneError && (
                        <div className="text-danger mt-1">
                          Por favor, introduce un teléfono válido.
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <InputGroup
                        className={`input-group-alternative ${
                          passwordError ? 'has-danger' : ''
                        }`}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Contraseña"
                          type="password"
                          autoComplete="off"
                          value={password}
                          onChange={handlePasswordChange}
                          invalid={passwordError}
                        />
                      </InputGroup>
                      {passwordError && (
                        <div className="text-danger mt-1">
                          Por favor, introduce una contraseña.
                        </div>
                      )}
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="primary"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <img
                            className="loading"
                            src={loadingGif}
                            alt="Cargando..."
                            style={{ height: '20px' }}
                          />
                        ) : (
                          'Iniciar Sesión'
                        )}
                      </Button>
                    </div>
                    {loginError && (
                      <div className="alert alert-danger mt-3">{loginError}</div>
                    )}
                  </Form>
          </CardBody>
        </Card>

      </Col>
    </>
  );
};

export default Login;
