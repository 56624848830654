// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const LoggedRoute = ({ children }) => {
  const token = localStorage.getItem('naseda_token'); // Check if the token exists
    
  if (token) {
    return <Navigate to="/admin/mis-clientes" replace />;
  }

  return children;
};

export default LoggedRoute;