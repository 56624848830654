// src/views/UsuarioRolesPermisos.jsx

import React, { useState } from "react";
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from 'classnames'; // Import classnames for tab toggling
import Header from "components/Headers/Header.js";
import background from 'assets/img/brand/fondoprincipal.png';
import UsuariosTable from "./UsuariosTable";

const UsuarioRolesPermisos = (props) => {
  const [activeTab, setActiveTab] = useState('usuarios'); // State to manage active tab

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <Container fluid className="flex-grow-1 d-flex flex-column">
          {/* <div className="search-filter-container">
            <div className="custom-title">
              <h1>Usuarios, roles y permisos</h1>
            </div>
          </div> */}

          {/* Tabs */}
          <Nav tabs className="mt-4">
            <NavItem style={{listStyle: 'none'}}>
              <NavLink
                className={classnames({ active: activeTab === 'usuarios' })}
                onClick={() => { toggle('usuarios'); }}
                style={{ cursor: 'pointer'}}
              >
                Usuarios
              </NavLink>
            </NavItem>
            <NavItem style={{listStyle: 'none'}}>
              <NavLink
                className={classnames({ active: activeTab === 'roles_permisos' })}
                onClick={() => { toggle('roles_permisos'); }}
                style={{ cursor: 'pointer' }}
              >
                Roles y Permisos
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane  tabId="usuarios">
              <Container className="mt-4">
              <UsuariosTable title="Gestión de Usuarios" />

              </Container>
            </TabPane>
            <TabPane tabId="roles_permisos">
              <Container className="mt-4">
                <h2>Gestión de Roles y Permisos</h2>
                <p>Aquí puedes gestionar los roles y permisos.</p>
              </Container>
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </>
  );
};

export default UsuarioRolesPermisos;