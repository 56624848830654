/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Login from "views/examples/Login.jsx";
import VentaCruzada from "views/VentaCruzada/VentaCruzada";
import Muestreo from "views/Muestreo/Muestreo";
import Copilot from "views/Copilot/Copilot";
import NasedaLabs from "views/NasedaLabs/NasedaLabs";
import BusinessIntelligence from "views/BusinessIntelligence/BusinessIntelligence";
import DetailsVentaCruzada from "views/VentaCruzada/DetailsVentaCruzada";
import UsuarioRolesPermisos from "views/UsuariosRolesPermisos/UsuariosRolesPermisos";

var routes = [
  {
    path: "/mis-clientes",
    name: "Mis Clientes",
    icon: "ni ni-money-coins text-primary",
    component: <VentaCruzada />,
    layout: "/admin", hidden: false

  },
  {
    path: "/muestreo",
    name: "Muestreo",
    icon: "ni ni-chart-pie-35 text-blue",
    component: <Muestreo />,
    layout: "/admin", hidden: false

  },
  {
    path: "/copilot",
    name: "Copilot",
    icon: "ni ni-chat-round text-orange",
    component: <Copilot />,
    layout: "/admin", hidden: false

  },
  {
    path: "/naseda-labs",
    name: "NASEDA Labs",
    icon: "ni ni-ruler-pencil text-yellow",
    component: <NasedaLabs />,
    layout: "/admin", hidden: false

  },
  {
    path: "/business-intelligence",
    name: "Business Intelligence",
    icon: "ni ni-building text-info",
    component: <BusinessIntelligence />,
    layout: "/admin", hidden: false

  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-building text-info",
    component: <Login />,
    layout: "/auth",

  },
  {
    path: "/mis-clientes/:id", 
    name: "Detalles",
    icon: "ni ni-cart text-success",
    component: <DetailsVentaCruzada />,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/usuarios-roles-y-permisos", 
    name: "Usuario, roles y permisos",
    icon: "ni ni-cart text-success",
    component: <UsuarioRolesPermisos />,
    layout: "/admin",
    hidden: true
  },


];
export default routes;
