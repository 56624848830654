// src/components/Breadcrumbs.jsx

import React, { useEffect, useState } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import routes from 'routes';
import axios from 'axios';
import config from 'config/config';
import '../../assets/css/BreadCrumbs.css'

const BreadcrumbsComponent = () => {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [dynamicNames, setDynamicNames] = useState({});

  useEffect(() => {
    const pathnames = location.pathname.split('/').filter((x) => x && x !== 'admin'); // Ignorar 'admin'
    const crumbList = [];

    pathnames.forEach((value, index) => {
      const to = `/${pathnames.slice(0, index + 1).join('/')}`;
      const route = routes.find((r) => {
        if (r.path.includes(':')) {
          const basePath = r.path.split('/:')[0];
          return to.startsWith(basePath);
        }
        return r.path === to;
      });

      console.log(`Processing segment: ${value}, route found: ${route ? route.name : 'None'}`);

      if (route) {
        if (route.path.includes(':')) {
          const paramName = route.path.split('/:')[1];
          const paramValue = pathnames[index];
          if (paramName === 'id') {
            console.log(`Found dynamic segment: ${paramName} with value: ${paramValue}`);
            // Solicitar el nombre dinámico basado en el ID si no está ya en el estado
            if (!dynamicNames[paramValue]) {
              console.log(`Fetching name for ID: ${paramValue}`);
              axios
                .get(`${config.backendURL}/clients/${paramValue}`, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('naseda_token')}`,
                  },
                })
                .then((response) => {
                  const clientName = response.data.client.business_name;
                  console.log(`Fetched name for ID ${paramValue}: ${clientName}`);
                  setDynamicNames((prev) => ({
                    ...prev,
                    [paramValue]: clientName,
                  }));
                })
                .catch((error) => {
                  console.error('Error fetching client name:', error);
                  setDynamicNames((prev) => ({
                    ...prev,
                    [paramValue]: 'Detalles',
                  }));
                });
            }
            crumbList.push({
              name: dynamicNames[paramValue] || 'Cargando...',
              path: to,
            });
          }
        } else {
          crumbList.push({
            name: route.name,
            path: to,
          });
        }
      } else {
        // Para rutas no definidas en el array de rutas
        const label = value.charAt(0).toUpperCase() + value.slice(1);
        crumbList.push({
          name: label,
          path: to,
        });
      }
    });

    console.log('Generated breadcrumbs:', crumbList);
    setBreadcrumbs(crumbList);
  }, [location.pathname, dynamicNames]);

  return (
    <Breadcrumb>
      {breadcrumbs.map((crumb, index) => {
        const isLast = index === breadcrumbs.length - 1;
        return isLast ? (
          <BreadcrumbItem active key={crumb.path}>
            {crumb.name}
          </BreadcrumbItem>
        ) : (
          <BreadcrumbItem key={crumb.path}>
            <Link to={crumb.path}>{crumb.name}</Link>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

export default BreadcrumbsComponent;