/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

*/

import { Container, Row, Col } from "reactstrap";

// core components
import Header from "components/Headers/Header.js";
import background from 'assets/img/brand/fondoprincipal.png';

const Copilot = (props) => {
  return (
    <>
      <Header />
      {/* Page content */}
      <Container 
      style={{
        backgroundImage: `url(${background})`, // Usa la imagen importada
        backgroundSize: "cover", // Para que la imagen cubra todo el contenedor
        backgroundPosition: "center center", // Para centrar la imagen
        minHeight: "100vh", // Ocupa al menos el 100% de la pantalla
        display: "flex", // Flexbox para que el contenido se distribuya correctamente
      }}
      fluid>
        <Row className="mt-2">
          <Col>
            {/* Aquí puedes colocar el contenido que desees */}
            <h1 className="text-black" >Copilot</h1>
            <p className="text-black ">Contenido</p>
          </Col>
        </Row>
        {/* Puedes añadir más filas y columnas si lo necesitas */}
      </Container>
    </>
  );
};

export default Copilot