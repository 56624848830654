import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Table,
} from 'reactstrap';
import { FaShoppingCart } from 'react-icons/fa'; // Import shopping cart icon
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import '../../assets/css/CustomTable.css'; // Ensure the path is correct
import config from 'config/config';
import axios from 'axios';
import { PageContentContext } from 'context/PageContentContext';

const CustomTable = ({ title }) => {
  const [sortConfig, setSortConfig] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const token = localStorage.getItem('naseda_token');
  const [empleadoOptions, setEmpleadoOptions] = useState([]);
  const [grupoOptions, setGrupoOptions] = useState([]);
  const [sucursalOptions, setSucursalOptions] = useState([]);

  // Modal state
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  // Filter states
  const [filterEmpleado, setFilterEmpleado] = useState('');
  const [filterGrupo, setFilterGrupo] = useState('');
  const [filterSucursal, setFilterSucursal] = useState('');

  const navigate = useNavigate(); // Initialize navigate

  // Debounce search term (optional)
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const { setPageContent } = useContext(PageContentContext);


  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300); // 300ms debounce

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams();
      params.append('page', currentPage);
      params.append('limit', itemsPerPage);

      // Include filters
      if (filterEmpleado) {
        params.append('filterEmpleado', filterEmpleado);
      }
      if (filterGrupo) {
        params.append('filterGrupo', filterGrupo);
      }
      if (filterSucursal) {
        params.append('filterSucursal', filterSucursal);
      }

      // Include search term
      if (debouncedSearchTerm) {
        params.append('searchTerm', debouncedSearchTerm);
      }

      // Include sorting parameters
      if (sortConfig) {
        params.append('sortKey', sortConfig.key);
        params.append('sortDirection', sortConfig.direction);
      }

      try {
        const response = await axios.get(
          `${config.backendURL}/clients?${params.toString()}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const result = response.data;
        setData(result.clients); // Data for the current page
        setTotalPages(result.totalPages); // Total number of pages
      } catch (error) {
        console.error('Error fetching data', error);
        // Handle error appropriately
      }
    };

    fetchData();
  }, [
    currentPage,
    itemsPerPage,
    filterEmpleado,
    filterGrupo,
    filterSucursal,
    debouncedSearchTerm,
    sortConfig,
    token,
  ]);

  useEffect(() => {
    if (data) {
      // Create an object for the page content
      const pageContentObject = {
        purpose: 'Mis Clientes es la página donde se puede consultar los clientes ligados a usted. Puedes dar click en una empresa/cliente para consultar su información más detallada. puedes buscar alguna empresa/cliente o filtrar por "Empleado", "Grupo" o "Sucursal"', 
        clientsData: data // The actual data
      };
  
      // Convert the pageContentObject to a JSON string
      const content = JSON.stringify(pageContentObject, null, 2);
      
      console.log(content);
      setPageContent(content);
    }
  }, [data, setPageContent]);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const response = await axios.get(`${config.backendURL}/clients/filters`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const result = response.data;

        setEmpleadoOptions(result.empleados);
        setGrupoOptions(result.grupos);
        setSucursalOptions(result.sucursales);
      } catch (error) {
        console.error('Error fetching filter options', error);
        // Handle error appropriately
      }
    };

    fetchFilterOptions();
  }, [token]);

  // Function to handle sorting
  const onSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    setCurrentPage(1); // Reset to first page on sort
  };

  // Function to get the sort indicator
  const getSortIndicator = (key) => {
    if (!sortConfig) return null;
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? ' 🔼' : ' 🔽';
    }
    return null;
  };

  // Pagination Component
  const PaginationComponent = () => {

    if (totalPages === 0) return null;

    const pages = [];

    // Always show the first page
    pages.push(1);

    // Calculate the page numbers to show between ellipses
    let leftSiblingIndex = Math.max(currentPage - 1, 2);
    let rightSiblingIndex = Math.min(currentPage + 1, totalPages - 1);

    const showLeftEllipsis = leftSiblingIndex > 2;
    const showRightEllipsis = rightSiblingIndex < totalPages - 1;

    // Add left ellipsis if needed
    if (showLeftEllipsis) {
      pages.push('...');
    } else {
      // Add pages between first page and current page
      for (let i = 2; i < leftSiblingIndex; i++) {
        pages.push(i);
      }
    }

    // Add the page numbers around the current page
    for (let i = leftSiblingIndex; i <= rightSiblingIndex; i++) {
      pages.push(i);
    }

    // Add right ellipsis if needed
    if (showRightEllipsis) {
      pages.push('...');
    } else {
      // Add pages between current page and last page
      for (let i = rightSiblingIndex + 1; i < totalPages; i++) {
        pages.push(i);
      }
    }

    // Always show the last page if totalPages > 1
    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return (
      <div className="pagination">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {pages.map((page, index) => {
          if (page === '...') {
            return (
              <span key={`ellipsis-${index}`} className="ellipsis">
                ...
              </span>
            );
          } else {
            return (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                className={currentPage === page ? 'active' : ''}
              >
                {page}
              </button>
            );
          }
        })}
        <button
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    );
  };

  const handleStartVentaCruzada = (sn_code) => {
    // navigate(`/admin/mis-clientes/${sn_code}`);
    alert('Pendiente de implementación');
  };

  const handleConsultarDetalles = (sn_code) => {
    navigate(`/admin/mis-clientes/${sn_code}`);
  };

  return (
    <>
      {/* Search and Filter Controls */}
      <div className="search-filter-container">
        <div className="custom-title">
          <h1>{title}</h1>
        </div>
        <div className="search-container">
          <input
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1); // Reset to first page on search
            }}
            className="search-input"
          />
        </div>
        <div className="filter-container">
          <Button color="primary" onClick={toggleModal}>
            Filtrar
          </Button>
        </div>
      </div>

      {/* Filter Modal */}
      {/* Filter Modal */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Filtros</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="empleadoSelect">Empleado</Label>
            <Input
              type="select"
              name="empleado"
              id="empleadoSelect"
              value={filterEmpleado}
              onChange={(e) => setFilterEmpleado(e.target.value)}
            >
              <option value="">Todos</option>
              {empleadoOptions.map((empleado) => (
                <option key={empleado} value={empleado}>
                  {empleado}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="grupoSelect">Grupo</Label>
            <Input
              type="select"
              name="grupo"
              id="grupoSelect"
              value={filterGrupo}
              onChange={(e) => setFilterGrupo(e.target.value)}
            >
              <option value="">Todos</option>
              {grupoOptions.map((grupo) => (
                <option key={grupo} value={grupo}>
                  {grupo}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="sucursalSelect">Sucursal</Label>
            <Input
              type="select"
              name="sucursal"
              id="sucursalSelect"
              value={filterSucursal}
              onChange={(e) => setFilterSucursal(e.target.value)}
            >
              <option value="">Todos</option>
              {sucursalOptions.map((sucursal) => (
                <option key={sucursal} value={sucursal}>
                  {sucursal}
                </option>
              ))}
            </Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              toggleModal();
              setCurrentPage(1); // Reset to first page on filter
            }}
          >
            Aplicar
          </Button>{' '}
          <Button
            color="secondary"
            onClick={() => {
              toggleModal();
              // Reset filters
              setFilterEmpleado('');
              setFilterGrupo('');
              setFilterSucursal('');
              setCurrentPage(1);
            }}
          >
            Limpiar
          </Button>
        </ModalFooter>
      </Modal>

      {/* Table */}
      <Table className="custom-table">
        <thead>
          <tr>
            <th onClick={() => onSort('sn_code')}>
              Código SN{getSortIndicator('sn_code')}
            </th>
            <th onClick={() => onSort('business_name')}>
              Nombre de la Empresa{getSortIndicator('business_name')}
            </th>
            <th onClick={() => onSort('pareto_status')}>
              Grupo{getSortIndicator('pareto_status')}
            </th>
            <th>Iniciar venta cruzada</th> {/* New header for actions */}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((item) => (
              <tr key={item.sn_code} onClick={() => {handleConsultarDetalles(item.sn_code)}}>
                <td  style={{cursor: 'pointer'}} >{item.sn_code}</td>
                <td  style={{cursor: 'pointer'}} >{item.business_name}</td>
                <td  style={{cursor: 'pointer'}} >{item.pareto_status}</td>
                <td className="actions-column">
                  <div className=' text-center'>
                    <Button
                      color="success"
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                       handleStartVentaCruzada(item.sn_code)
                      }}
                    >
                      <FaShoppingCart />
                    </Button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" style={{ textAlign: 'center' }}>
                Sin información
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Pagination */}
      <PaginationComponent />
    </>
  );
};

export default CustomTable;