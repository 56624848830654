import React, { useEffect, useRef, useState, useContext } from 'react';
import '../../assets/css/SupportBubble.css';
import axios from 'axios';
import config from '../../config/config';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import naseda_img from '../../assets/img/chat/NASEDA_CIRCULO.png';
import user_img from '../../assets/img/chat/user.png';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Importa Bootstrap Icons
import { PageContentContext } from 'context/PageContentContext'; // Importar el contexto
import { BiSend, BiTrash } from 'react-icons/bi'; // Importar react-icons
import ReactMarkdown from 'react-markdown';

const SupportChatModal = ({ show, handleClose, predefinedQuestion }) => {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const chatBodyRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const token = localStorage.getItem('naseda_token');
    const { pageContent } = useContext(PageContentContext); // Acceder al contenido de la página

    // Function to fetch messages from backend if thread_id exists
    const fetchExistingMessages = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${config.backendURL}/getNasedaCopilotMessages`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            if (response.data.messages && response.data.messages.length > 0) {
                console.log(response.data.messages)
                setMessages(response.data.messages.reverse().map((message) => ({
                    sender: message.role, // Deberías retornar el emisor (support o user)
                    text: message.content[0].text.value
                })));
            } else {
                const newMessage = { sender: 'assistant', text: '¿En qué te puedo ayudar?' };
                setMessages([newMessage]);
            }
        } catch (error) {
            console.error('Error fetching existing messages:', error);
        } finally {
            setIsLoading(false);

        }
    };

    useEffect(() => {
        if (show) {
            fetchExistingMessages();
            // Si se proporciona una pregunta predeterminada, la configuramos
            if (predefinedQuestion) {
                setInputMessage(predefinedQuestion);
            }
        }
    }, [show, predefinedQuestion]);

    // Scroll to the bottom whenever messages update
    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    }, [messages]);

    const fetchSupportResponse = async (userMessage) => {
        try {
            const response = await axios.post(`${config.backendURL}/nasedaCopilotPageQuestion`,
                {
                    message: userMessage,
                    pageContent: pageContent, // Enviar el contenido de la página
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            );
            return response.data.message;
        } catch (error) {
            console.error('Error fetching support response:', error);
            return 'Lo siento, algo salió mal. Por favor intenta nuevamente.';
        }
    };

    const handleSendMessage = async () => {
        if (inputMessage.trim() !== '') {
            const userMessage = { sender: 'user', text: inputMessage };
            setMessages([...messages, userMessage]);
            setInputMessage('');
            setIsLoading(true);

            try {
                const supportResponse = await fetchSupportResponse(userMessage.text);
                const supportMessage = { sender: 'assistant', text: supportResponse };
                setMessages(prevMessages => [...prevMessages, supportMessage]);
            } catch (error) {
                console.error('Error during message handling:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleClearThreadId = async () => {
        try {
            await axios.post(`${config.backendURL}/resetCopilotConversation`,
                {

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            );
            fetchExistingMessages();
        } catch (error) {
            console.error('Error fetching support response:', error);
            return 'Lo siento, algo salió mal. Por favor intenta nuevamente.';
        }
    };

    const handleCloseModal = () => {
        handleClose();
        setTimeout(() => {
            setIsLoading(false);
            setMessages([]);
            setInputMessage('');
        }, 300);
    };

    return (
        <Modal isOpen={show} size='lg' toggle={handleCloseModal} centered>
            <ModalHeader toggle={handleCloseModal} className="d-flex justify-content-between align-items-center w-100">
                <div className="d-flex justify-content-between w-100">
                    <h1 className="m-0 mx-4">NASEDA Copilot</h1>
                    <Button
                        color="danger"
                        onClick={handleClearThreadId}
                        className="ms-auto d-flex align-items-center">
                        <BiTrash size={19}></BiTrash>
                    </Button>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="support-chat-body" ref={chatBodyRef}>
                    {messages.map((message, index) => (
                        <div
                            key={index}
                            className={`d-flex align-items-start mb-4 animate__animated animate__fadeIn ${message.sender === 'user' ? 'flex-row-reverse' : ''
                                }`}
                        >
                            <img
                                src={message.sender === 'user' ? user_img : naseda_img}
                                className='rounded-circle chat-avatar'
                                alt={message.sender === 'user' ? "User" : "NASEDA Copilot"}
                            />
                            <div className={`message-bubble ${message.sender}`}>
                                <ReactMarkdown className=''>{message.text}</ReactMarkdown>
                            </div>
                        </div>
                    ))}
                    {isLoading && (
                        <div className="d-flex justify-content-center mt-3">
                            <div className="loader"></div>
                        </div>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <div className='d-flex w-100' style={{ gap: 10 }}>
                    <Input
                        type="text"
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
                        placeholder="Escribe tu mensaje..."
                        className="me-2"
                    />
                    <Button color="primary" onClick={handleSendMessage}>
                        <BiSend size={20}/> {/* Usar react-icons */}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default SupportChatModal;