import { Container, Row, Col } from "reactstrap";

// core components
import Header from "components/Headers/Header.js";
import background from 'assets/img/brand/fondoprincipal.png';

const NasedaLabs = (props) => {
  return (
    <>
      <Header />
      {/* Page content */}
      <Container 
      style={{
        backgroundImage: `url(${background})`, // Usa la imagen importada
        backgroundSize: "cover", // Para que la imagen cubra todo el contenedor
        backgroundPosition: "center center", // Para centrar la imagen
        minHeight: "100vh", // Ocupa al menos el 100% de la pantalla
        display: "flex", // Flexbox para que el contenido se distribuya correctamente
      }}
      fluid>
        <Row className="mt-2">
          <Col>
            {/* Aquí puedes colocar el contenido que desees */}
            <h1 className="text-black" >NASEDA Labs</h1>
            <p className="text-black ">Contenido</p>
          </Col>
        </Row>
        {/* Puedes añadir más filas y columnas si lo necesitas */}
      </Container>
    </>
  );
};

export default NasedaLabs