// src/views/DetailsPage/DetailsVentaCruzada.jsx

import React, { useContext, useEffect, useState } from 'react';
import {  useParams } from 'react-router-dom';
import { Container, Spinner, Alert, Button, Card, CardBody, Collapse, CardText } from 'reactstrap';
import Header from 'components/Headers/Header.js'; // Ajusta la ruta de importación según sea necesario
import background from 'assets/img/brand/fondoprincipal.png'; // Ajusta la ruta según sea necesario
import '../../assets/css/DetailsVentaCruzada.css'; // Asegúrate de que la ruta es correcta
import axios from 'axios';
import config from 'config/config';
import { FaChevronDown, FaChevronUp, FaShoppingCart } from 'react-icons/fa'; // Importa los íconos
import { PageContentContext } from 'context/PageContentContext';

const DetailsVentaCruzada = () => {
  const { id } = useParams(); // Extrae 'id' de la URL
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPhoneNumbersOpen, setIsPhoneNumbersOpen] = useState(false);
  const [isClientEmailsOpen, setIsClientEmailsOpen] = useState(false);
  const [isCobranzaEmailsOpen, setIsCobranzaEmailsOpen] = useState(false);
  const [isHistorialVentasOpen, setIsHistorialVentasOpen] = useState(false);
  const { setPageContent } = useContext(PageContentContext);
  const token = localStorage.getItem('naseda_token');


  useEffect(() => {
    // Fetch client data using Axios
    const fetchClient = async () => {
      try {
        const response = await axios.get(`${config.backendURL}/clients/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setClient(response.data.client); // Asumiendo que los datos del cliente están en response.data.client
      } catch (err) {
        console.error('Error fetching client data:', err);
        setError('Error fetching client data.');
      } finally {
        setLoading(false);
      }
    };

    fetchClient();
  }, [id]);

  useEffect(() => {
    if (client) {
      // Convertir los datos del cliente a una cadena JSON para enviar al backend
      const pageContentObject = {
        purpose: 'Esta es la ventana de detalles de un cliente seleccionado. En esta se puede consultar la información del cliente',
        clientData: client
      };

      // Convert the pageContentObject to a JSON string
      const content = JSON.stringify(pageContentObject, null, 2);

      console.log(content);
      setPageContent(content);
    }
  }, [client, setPageContent]);

  if (loading) {
    return (
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <Container className="d-flex justify-content-center align-items-center flex-grow-1">
          <Spinner color="primary" />
        </Container>
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <Container className="d-flex justify-content-center align-items-center flex-grow-1">
          <Alert color="danger">{error}</Alert>
        </Container>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Header />
      <Container className="flex-grow-1 ">
        {client ? (
          <div className="details-container">
            <div className="row">
              {/* Left Column */}
              <div className="col-md-8">
                <div className="left-column">
                  {/* Card Component */}
                  <Card className="mb-4">
                    <CardBody>
                      <div className="d-flex justify-content-between align-items-center">
                        <CardText className='mb--2 font-italic'>{client.sn_code}</CardText>
                        <span
                          style={{
                            color: 'white',
                            backgroundColor: client.details.active ? 'green' : 'red',
                            borderRadius: 10,
                            paddingInline: 10
                          }}
                        >
                          {client.details.active ? 'Activo' : 'Inactivo'}
                        </span>
                      </div>
                      <CardText>
                        <h1>{client.business_name}</h1>
                      </CardText>
                      <Button color="primary" onClick={() => { /* Do nothing for now */ }}>
                        Generar
                      </Button>
                      <CardText className='mt-2'>
                        <strong>Descripción:</strong> {client.details.description}
                      </CardText>
                    </CardBody>
                  </Card>

                  {/* Display nested details */}
                  {client.details && (
                    <>
                      {/* Contact Details */}
                      {client.details.contact_details && (
                        <>
                          <Card className="mb-4">
                            <CardBody>
                              <h3 className="mt-3">Detalles de Contacto</h3>
                              <p>
                                <strong>Nombre de la persona contacto:</strong>{' '}
                                {client.details.contact_details.contact_name}
                              </p>
                              {/* Phone Numbers */}
                              {client.details.contact_details.phone_numbers &&
                                client.details.contact_details.phone_numbers.length > 0 && (
                                  <>
                                    <p>
                                      <strong
                                        onClick={() => setIsPhoneNumbersOpen(!isPhoneNumbersOpen)}
                                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                      >
                                        Números de Teléfono:
                                        <span style={{ marginLeft: '8px' }}>
                                          {isPhoneNumbersOpen ? <FaChevronUp /> : <FaChevronDown />}
                                        </span>
                                      </strong>
                                    </p>
                                    <Collapse isOpen={isPhoneNumbersOpen}>
                                      <ul>
                                        {client.details.contact_details.phone_numbers.map(
                                          (phone, index) => (
                                            <li key={index}>
                                              {phone.number}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </Collapse>
                                  </>
                                )}
                              {/* Emails */}
                              {client.details.contact_details.emails &&
                                client.details.contact_details.emails.length > 0 && (
                                  <>
                                    <p>
                                      <strong
                                        onClick={() => setIsClientEmailsOpen(!isClientEmailsOpen)}
                                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                      >
                                        Correos Electrónicos de cliente
                                        <span style={{ marginLeft: '8px' }}>
                                          {isClientEmailsOpen ? <FaChevronUp /> : <FaChevronDown />}
                                        </span>
                                      </strong>
                                    </p>
                                    <Collapse isOpen={isClientEmailsOpen}>
                                      <ul>
                                        {client.details.contact_details.emails
                                          .filter((email) => email.label === 'cliente')
                                          .map((email, index) => (
                                            <li key={index}>{email.email}</li>
                                          ))}
                                      </ul>
                                    </Collapse>
                                    <p>
                                      <strong
                                        onClick={() => setIsCobranzaEmailsOpen(!isCobranzaEmailsOpen)}
                                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                      >
                                        Correos Electrónicos de cobranza
                                        <span style={{ marginLeft: '8px' }}>
                                          {isCobranzaEmailsOpen ? <FaChevronUp /> : <FaChevronDown />}
                                        </span>
                                      </strong>
                                    </p>
                                    <Collapse isOpen={isCobranzaEmailsOpen}>
                                      <ul>
                                        {client.details.contact_details.emails
                                          .filter((email) => email.label === 'cobranza')
                                          .map((email, index) => (
                                            <li key={index}>{email.email}</li>
                                          ))}
                                      </ul>
                                    </Collapse>
                                  </>
                                )}
                            </CardBody>
                          </Card>
                        </>
                      )}

                      {/* Product Details */}
                      {client.details.product_details && (
                        <>
                          <Card className="mb-4">
                            <CardBody>
                              <h3 className="mt-3">Datos de productos</h3>
                              {/* Products Sold */}
                              {client.details.product_details.products_sold &&
                                client.details.product_details.products_sold.length > 0 && (
                                  <>
                                    <p
                                      onClick={() =>
                                        setIsHistorialVentasOpen(!isHistorialVentasOpen)
                                      }
                                      style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                    >
                                      <strong>
                                        Historial de ventas:
                                        <span style={{ marginLeft: '8px' }}>
                                          {isHistorialVentasOpen ? <FaChevronUp /> : <FaChevronDown />}
                                        </span>
                                      </strong>
                                    </p>
                                    <Collapse isOpen={isHistorialVentasOpen}>
                                      <ul>
                                        {client.details.product_details.products_sold.map(
                                          (product, index) => (
                                            <li key={index}>
                                              {product.product_name} {'(Cantidad: '}{product.quantity}{')'}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </Collapse>
                                  </>
                                )}
                              {/* Insights */}
                              {client.details.product_details.insights &&
                                client.details.product_details.insights.length > 0 && (
                                  <>
                                    <p>
                                      <strong>Insights:</strong>
                                    </p>
                                    <ul>
                                      {client.details.product_details.insights.map(
                                        (insight, index) => (
                                          <li key={index}>{JSON.stringify(insight)}</li>
                                        )
                                      )}
                                    </ul>
                                  </>
                                )}
                            </CardBody>
                          </Card>
                        </>
                      )}

                      {/* Other Details */}
                      {client.details.other_details && (
                        <>
                          <Card className="mb-4 ">
                            <CardBody>
                              <h3 className="mt-3">Otros Detalles</h3>
                              <p>
                                <strong>Fecha de Registro:</strong>{' '}
                                {client.details.other_details.registration_date}
                              </p>
                              <p>
                                <strong>Sucursal:</strong> {client.business_branch}
                              </p>
                              <p>
                                <strong>Destinatario de factura:</strong>{' '}
                                {client.details.other_details.invoice_recipient}
                              </p>
                              <p>
                                <strong>Gestor cobranza:</strong>{' '}
                                {client.details.other_details.gestor_cobranza}
                              </p>
                              <p>
                                <strong>Comentarios:</strong>{' '}
                                {client.details.other_details.comments}
                              </p>
                              <p>
                                <strong>Lista de precios:</strong>{' '}
                                {client.details.other_details.price_list}
                              </p>
                              <p>
                                <strong>Forma de envío:</strong>{' '}
                                {client.details.other_details.shipping_method}
                              </p>
                              <p>
                                <strong>Banco por defecto:</strong>{' '}
                                {client.details.other_details.default_bank}
                              </p>
                              <p>
                                <strong>Tipo de operación comercial:</strong>{' '}
                                {client.details.other_details.type_of_commercial_operation}
                              </p>
                              {/* Agrega más campos de other_details según sea necesario */}
                            </CardBody>
                          </Card>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              {/* Right Column */}
              <div className="col-md-4">
                <div className="left-column">
                  <Card className="">
                    <CardBody>
                      <h4>Información Adicional</h4>
                      {/* Payment Terms */}
                      <p>
                        <strong>Pareto Status:</strong> {client.pareto_status}
                      </p>
                      <p>
                        <strong>Estatus de crédito:</strong> {client.payment_terms}
                      </p>

                      <p>
                        <strong>Prioridad:</strong> {client.priority}
                      </p>
                      <p>
                        <strong>Condiciones de pago:</strong> {client.payment_conditions}
                      </p>

                      {/* Result Cross Selling */}
                      {client.result_cross_selling &&
                        client.result_cross_selling.length > 0 && (
                          <>
                            <h5 className="mt-3">Resultados de Venta Cruzada</h5>
                            <ul>
                              {client.result_cross_selling.map((result, index) => (
                                <li key={index}>
                                  Fecha:{' '}
                                  {new Date(result.result_date).toLocaleDateString()} - ID
                                  Resultado: {result.result_id}
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                    </CardBody>
                  </Card>

                  <Card className="mt-4">
                    <CardBody className="text-center"> 
                      <Button color="success" onClick={() => alert('Pendiente de implementación')}>
                        Venta Cruzada
                        <FaShoppingCart />
                      </Button>
                    </CardBody>
                  </Card>
                </div>

              </div>

            </div>
          </div>
        ) : (
          <p>No se encontró el registro.</p>
        )}
      </Container>
    </div>
  );
};

export default DetailsVentaCruzada;