// src/layouts/Admin.js

import React, { useEffect, useRef, useState } from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Chat from '../assets/img/chat/Chat.png';

import routes from "routes.js";
import SupportChatModal from "components/Modal/SupportChatModal";
import 'bootstrap-icons/font/bootstrap-icons.css'; // Importa Bootstrap Icons

// Importa el proveedor del contexto
import { PageContentProvider } from 'context/PageContentContext';

const Admin = (props) => {
  const mainContent = useRef(null);
  const location = useLocation();

  // Estado para controlar el Modal
  const [supportChatOpen, setSupportChatOpen] = useState(false);

  const handleSupportChatOpen = () => setSupportChatOpen(true);
  const handleSupportChatClose = () => setSupportChatOpen(false);


  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };



  return (
    <>
      <Sidebar
        {...props}
        routes={routes.filter((route) => !route.hidden)}
        logo={{
          innerLink: "/admin/venta-cruzada",
          imgSrc: require("../assets/img/brand/logo-naseda.png"),
          imgAlt: "...",
        }}
      />
      {/* Envolver el contenido principal con el proveedor del contexto */}
      <PageContentProvider>
        <div className="main-content" ref={mainContent}>
          <AdminNavbar
            {...props}
            brandText={''}
          />
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/admin/index" replace />} />
          </Routes>
          <Container fluid>
            {/* <AdminFooter /> */}
          </Container>

          {/* Burbuja de Soporte */}
          <div
            className="support-bubble animate__animated animate__fadeIn"
            onClick={handleSupportChatOpen}
          >
            <img
              src={Chat}
              alt="Support Chat"
              style={{
                marginTop: '6px',
                width: '50%', // Make the image fit within the bubble
                objectFit: 'contain' // Ensure the image doesn't stretch
              }}
            />
          </div>
          {/* Modal de Soporte */}
          <SupportChatModal
            show={supportChatOpen}
            handleClose={handleSupportChatClose}
          />
        </div>
      </PageContentProvider>
    </>
  );
};

export default Admin;