// ParticlesBackground.js
import React, { useEffect } from 'react';

const ParticlesBackground = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `${process.env.PUBLIC_URL}/js/particles.min.js`; 
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      // ParticlesJS config
      window.particlesJS('particles-js', {
        particles: {
          number: {
            value: 80,
            density: {
              enable: true,
              value_area: 900,
            },
          },
          color: {
            value: '#4287f5',
          },
          shape: {
            type: 'circle',
            stroke: {
              width: 1,
              color: '#9b51e0',
            },
          },
          opacity: {
            value: 0.5,
            random: false,
          },
          size: {
            value: 3,
            random: true,
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: '#9b51e0',
            opacity: 0.5,
            width: 1,
          },
          move: {
            enable: true,
            speed: 2,
            direction: 'none',
            random: false,
            straight: false,
            out_mode: 'out',
            bounce: false,
          },
        },
        interactivity: {
          detect_on: 'canvas',
          events: {
            onhover: {
              enable: true,
              mode: 'grab',
            },
            onclick: {
              enable: true,
              mode: 'push',
            },
          },
        },
        retina_detect: true,
      });
    };
  }, []);

  return <div id="particles-js" style={styles.container}></div>;
};

const styles = {
    container: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 0, 
      top: 0,
      left: 0,
    },
  };

export default ParticlesBackground;